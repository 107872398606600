<template>
  <div class="card">
    <div class="card-header header-elements-inline">

      <legend class="font-weight-semibold text-uppercase font-size-sm">
        <i class="icon-file-text2"></i>
        Enter Indent Information
      </legend>

      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>

    </div>

    <div class="card-body">

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Initiated Branch:</label>
        <div class="col-md-4">
          <select id="cmb_initiated_ibr" data-placeholder="Select Initiated Branch" class="form-control" v-if="indent" v-model="indent.initiated_branch.code" >
            <option v-for="branch in branches" v-bind:value="branch.code">
              {{ branch.name }}
            </option>
          </select>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">Beneficiary Branch:</label>
        <div class="col-md-4">
          <select id="cmb_beneficiary_ibr" data-placeholder="Select Beneficiary Branch" class="form-control" v-if="indent" v-model="indent.beneficiary_branch.code">
            <option v-for="branch in branches" v-bind:value="branch.code">
              {{ branch.name }}
            </option>
          </select>
        </div>

      </div>


      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Item:</label>
        <div class="col-md-4">
          <select id="cmb_item" data-placeholder="Select Item" class="form-control" v-if="indent" v-model="indent.item.code">
            <option v-for="item in items" v-bind:value="item.code">
              {{ item.name }}
            </option>
          </select>
        </div>

        <label class="col-md-2 col-form-label font-weight-semibold">Purpose :</label>
        <div class="col-md-2">
          <select data-placeholder="Select Item" class="form-control" v-if="indent" v-model="indent.purpose">
            <option value="1">New</option>
            <option value="2">Upgradation</option>
            <option value="3">Replacement</option>
            <option value="4">Stock</option>
          </select>
        </div>

        <label class="col-md-1 col-form-label font-weight-semibold">Qty:</label>
        <div class="col-md-1">
          <input class="form-control text-right" type="number" name="txtQty"  placeholder="Qty" v-if="indent" v-model="indent.qty">
        </div>

      </div>

      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Asset Tags:</label>
        <div class="col-md-10">

          <div class="table-responsive">
            <table id="indent-table"
                   class="table table-borderless table-condensed"
                   data-search="false"
                   data-pagination="true"
                   data-show-refresh="false"
                   data-show-columns="false"
                   data-page-list="[10, 25, 50, 100, ALL]"
                   data-show-footer="false"
                   data-toggle="context"
                   data-target=".context-table">
              <thead>
              <tr>
                <th data-field="id" data-class="d-none">id</th>
                <th data-width="50" data-field="code" data-align="center" >#</th>
                <th data-field="name" >Asset Tag</th>
                <th data-field="name" >Group</th>
                <th data-field="name" >Item</th>
                <th data-field="name" >Category</th>
                <!--<th data-field="name" >Brand</th>-->
              </tr>
              </thead>
              <tbody>
              <tr>
                <td data-class="d-none">
                  id
                </td>
                <td>
                  <div class="uniform-checker">
                    <span class="checked">
                      <input type="checkbox" class="form-check-input-styled" data-fouc="">
                    </span>
                  </div>
                </td>
                <td>1234-1234-1234</td>
              </tr>
              </tbody>
              <tfoot>
              </tfoot>
            </table>
          </div>

        </div>
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary legitRipple">Save <i class="icon-paperplane ml-2"></i></button>
      </div>

    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'IndentForm',
    data() {
      return {
        indent: JSON.parse('{"id":"","code":0,"date":"0001-01-01","initiated_branch":{"id":"","code":0,"name":""},"beneficiary_branch":{"id":"","code":0,"name":""},"item":{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0,"system_object":false},"purpose":1,"qty":0,"list":[]}'),
        mytable: {},
        assettags: [],
        branches: [],
        items: []
      }
    },
    component: {

    },
    props: {
      myindent: {
        type: Object,
        required: true,
        default: JSON.parse('{"id":"","code":0,"date":"0001-01-01","initiated_branch":{"id":"","code":0,"name":""},"beneficiary_branch":{"id":"","code":0,"name":""},"item":{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0,"system_object":false},"purpose":1,"qty":0,"list":[]}')
      }
    },
    beforeMount () {
      this.indent = this.myindent; // save props data to itself's data and deal with tms
    },
    watch: {

    },
    created () {

    },
    mounted(){
      let self = this;
      self.$data.mytable = $('#indent-table');
      self.$data.mytable.bootstrapTable();
      self.loadBranches();
      self.loadItems();

      $('#cmb_initiated_ibr').focus();
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeModal();
      },
      loadAssetTags(ibr){
        this.$data.assettags = [];
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/it/assettag/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.mytable.bootstrapTable('load', data.data);
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadBranches(){
        let self = this;
        self.$data.branches = [];
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/branch/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.branches = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadItems(){
        let self = this;
        self.$data.items = [];
        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/item/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.items = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveIndent(){
        let self = this;

        //self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD') + 'T00:00:00Z';

        self.$data.indent.group.code = parseInt(self.$data.ledger.group.code);
        self.$data.indent.opening_balance =  $('#cmbcrdr').val() *  Math.abs(parseFloat(self.$data.ledger.opening_balance));

        if(self.$data.ledger.group.code < 1){
          alert("Invalid Parent");
          return
        }

        const requestOptions = {
          method:  (self.$data.ledger.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.indent)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/indent/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.$data.indent = JSON.parse('{"id":"","code":0,"date":"0001-01-01","initiated_branch":{"id":"","code":0,"name":""},"beneficiary_branch":{"id":"","code":0,"name":""},"item":{"id":"","code":0,"nature":0,"name":"","p_name":"","group":{"id":"","code":0,"type":0,"nature":0,"name":"","p_name":"","hsn":"","gstrate":0,"itcrate":0,"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"subunit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"conv_fact":0,"has_bom":false,"sys_obj":false},"category":{"id":"","code":0,"name":"","p_name":""},"brand":{"id":"","code":0,"name":"","print_name":""},"unit":{"id":"","code":0,"name":"","digits":0,"sys_obj":false},"rate":0,"min_qty":0,"max_qty":0,"system_object":false},"purpose":1,"qty":0,"list":[]}');
            self.$parent.$parent.closeModal();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: `failed to load data!`, icon:  "error"} );
        });
      }

    }

  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
